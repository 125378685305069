





































import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsPaciente } from "@/utils/utils-paciente";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  $_veeValidate: { validator: "new" },
  components: {
    PacienteFichaShow: () =>
      import("@/views/pacientes/paciente-ficha-show.vue"),
  },
})
export default class informacion_basica_paciente extends Vue {
  @Prop() paciente!: paciente;
  public ImgPaciente(paciente: paciente) {
    return UtilsPaciente.getrutaImagen(paciente);
  }
}
